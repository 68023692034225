import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"

const Post = ({ pageContext }) => {
    const {featured_media ,title, content} = pageContext
    return (
        <Layout>
            <SEO title={title} />
            <h1>{title}</h1>
            <img alt="kép" src={featured_media.source_url}></img>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </Layout>
    )
}


export default Post

